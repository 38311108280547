/* Hide scrollbar for Chrome, Safari and Opera */
#scrollContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#scrollContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/*@media screen and (orientation:portrait) {*/
/*    #headerRow h1{*/
/*        max-width: 25ch;*/
/*    }*/
/*}*/


#headerRow h1{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-margins-vertical{
    margin-top: 3rem !important;
    margin-left: 4rem !important;
}

.header-margins-horizontal{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    margin-left: 1rem !important;
}



img {
    border-style: none;
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

button {
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
}

/**, ::after, ::before {*/
/*    box-sizing: inherit;*/
/*}*/

button {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button {
    border-radius: 0;
}

/*.btn.btn-sm {*/
/*    line-height: calc(1rem - 1px);*/
/*    letter-spacing: .073em;*/
/*    font-size: .458333rem;*/
/*    font-weight: 500;*/
/*    height: 1rem;*/
/*    padding: 0 .5rem;*/
/*}*/

/*.btn {*/
/*    margin: .25rem .5rem .25rem 0;*/
/*}*/

/*.btn.btn-link {*/
/*    margin: .25rem 0;*/
/*}*/


timeline-container ul {
    margin-left: 0;
    padding-left: 0;
    list-style-position: inside;
    margin-top: 0;
    margin-bottom: 0;
}

.main-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    background: #fafafa;
}

.main-container .content-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
}

.main-container .content-container .content-area {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 1rem;
}

.main-container .content-container .content-area > :first-child {
    margin-top: 0;
}

@media print {
    .main-container {
        height: auto;
    }
}

/*! CSS Used from: Embedded */
clr-icon {
    display: inline-block;
    margin: 0;
    height: 16px;
    width: 16px;
    vertical-align: middle;
    fill: currentColor;
    transition: transform .5s;
}

clr-icon > * {
    height: 100%;
    width: 100%;
    display: block;
    pointer-events: none;
}

clr-icon > svg {
    transition: inherit;
}

clr-icon .clr-i-solid, clr-icon > svg title {
    display: none;
}

/*! CSS Used from: Embedded */
.clr-timeline {
    display: flex;
    padding: .5rem;
}

.clr-timeline-step {
    display: flex;
    position: relative;
    transition: transform .2s;
}

.hoverer {
    z-index: 2;
}

.hoverer:hover {
    cursor: pointer;
}

.clr-timeline-step clr-icon {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
}

/*.clr-timeline-step clr-icon[shape=spinner] {*/
/*    animation: spin 1s linear infinite;*/
/*}*/

/*.clr-timeline-step clr-icon[shape=circle] {*/
/*    !*color: #737373;*!*/
/*}*/

/*.clr-timeline-step clr-icon[shape=dot-circle] {*/
/*    color: #0079b8;*/
/*}*/
.clr-timeline-step clr-icon{
    color: #0079b8;
}

/*.clr-timeline-step clr-icon[shape=success-standard] {*/
/*    color: #60b515;*/
/*}*/

/*.clr-timeline-step clr-icon[shape=error-standard] {*/
/*    color: #c92100;*/
/*}*/

.clr-timeline-step-header {
    /*font-size: .541667rem;*/
    font-size: 1.1375007rem;
    /*color: #737373;*/
    line-height: 1.575rem;
    transition: transform .5s;
}

.btn.files{
    transition: transform .5s;
}

.clr-timeline-step-title {
    /*font-size: .541667rem;*/
    font-size: 1.1375007rem;
    font-weight: bold;
    /*color: #565656;*/
    line-height: 1.575rem;
    margin-bottom: .25rem;
    transition: transform .5s;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clr-timeline-step-description {
    /*font-size: .458333rem;*/
    font-size: .9624993rem;
    /*color: #565656;*/
    /*line-height: .66667rem;*/
    line-height: 1.4rem;
    transition: transform .5s;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clr-timeline-step-description button {
    display: block;
    margin-top: .333333rem;
}

.clr-timeline-step-description img {
    width: 100%;
    margin-top: .333333rem;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step {
    flex-direction: column;
    /*width: 100%;*/
    min-width: 15rem;
    margin-left: 5rem;
}

/*:root{*/
/*    --scaling: 1*/
/*}*/

.clr-timeline.clr-timeline-horizontal .clr-timeline-step:not(:last-of-type) .clr-timeline-step-body::before {
    content: "";
    position: absolute;
    /*margin-top: .25rem;*/
    background: #9a9a9a;
    /*height: .083333rem;*/
    height: .1rem;
    width: calc(100% - (1.5rem - 2 * 0.083333rem) + 5rem);
    /*transform: translate(1.416667rem, -0.7916665rem) scale(var(--scaling));*/
    transform: translate(1.416667rem, -0.7916665rem) ;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-header {
    white-space: nowrap;
    margin-bottom: .333333rem;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-body {
    display: flex;
    flex-direction: column;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-title {
    margin-top: .333333rem;
}

.clr-timeline.clr-timeline-vertical {
    flex-direction: column;
    min-width: 13.33333rem;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step {
    flex-direction: row;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step:not(:last-of-type) {
    margin-bottom: 1.5rem;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step:not(:last-of-type) .clr-timeline-step-body::before {
    content: "";
    position: absolute;
    background: #9a9a9a;
    width: .083333rem;
    height: calc(100% + 2 * 0.083333rem);
    transform: translate(-1.2916665rem, 1.466667rem);
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step-header {
    text-align: right;
    /*min-width: 2.5rem;*/
    min-width: 7rem;
    width: 2.5rem;
    margin-right: .5rem;
    /*margin-top: .25rem;*/
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step-body {
    display: flex;
    flex-direction: column;
    min-width: 7.333333rem;
    margin-left: .5rem;
    /*margin-top: .25rem;*/
}

/*! CSS Used keyframes */
@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.masked-overflow {
    /* scroll bar width, for use in mask calculations */
    --scrollbar-width: 8px;

    /* mask fade distance, for use in mask calculations */
    --mask-height: 32px;

    /* If content exceeds height of container, overflow! */
    overflow-y: auto;

    /* Our height limit */
    height: 300px;

    /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
    padding-bottom: var(--mask-height);

    /* Keep some space between content and scrollbar */
    padding-right: 20px;

    /* The CSS mask */

    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
            to bottom,
            transparent,
            black var(--mask-height),
            black calc(100% - var(--mask-height)),
            transparent
    );

    /* Here we scale the content gradient to the width of the container
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);

    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    /* Position the content gradient in the top left, and the
  scroll gradient in the top right */
    mask-position: 0 0, 100% 0;

    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;
}

/* below is for pulsating functionality */
/*Ripple*/
.ripple {
    width: 10px;
    height: 10px;
    opacity: 0;
    transform: scale(0);
    background: rgba(0, 102, 255, 0.6);
    border-radius: 50%;
    position: fixed;
}
/*Animate Function*/
.animate {
    animation: ripple-mo 1s cubic-bezier(0, 0, 0.2, 1);
}
@keyframes ripple-mo {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(20);
        opacity: 0;
    }
}

.blurredEntry{
    padding-left: 5px;
    color: transparent;
    text-shadow: rgb(255, 255, 255) 0px 0px 7px;
}

.blurredEntryHideCount{
    display: none!important;
}

.blurredEntryIsShared{
    color: green!important;
}

.blurredEntryIsSharedButNoMorePro{
    color: rgb(255,193,7)!important;
}

