/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

/*=============== VARIABLES CSS ===============*/
#nav-menu {
  --header-height: 3rem;

  /*========== Colors ==========*/
  --hue: 174;
  --sat: 63%;
  --first-color: #008ADC;
  --first-color-alt: #008ADC;
  --title-color: hsl(var(--hue), 12%, 15%);
  --text-color: hsl(var(--hue), 8%, 35%);
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #FFF;

  /*========== Font and typography ==========*/
  --body-font: 'Open Sans', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --tiny-font-size: .625rem;

  /*========== z index ==========*/
  z-index: 1058;
}

@media screen and (min-width: 968px) {
  #nav-menu {
    --h1-font-size: 2.25rem;
    --normal-font-size: 1rem;
  }
}

#nav-menu {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#nav-menu {
  scroll-behavior: smooth;
}

#nav-menu {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

#nav-menu  ul {
  list-style: none;
  margin-top: unset;
  margin-bottom: unset;
}

#nav-menu a {
  text-decoration: none;
}

#nav-menu img {
  max-width: 100%;
  height: auto;
}

#nav-menu .section {
  padding: 4.5rem 0 2rem;
}

#nav-menu .section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  text-align: center;
  margin-bottom: 1.5rem;
}

#nav-menu .section__height {
  height: 100vh;
}

@media (orientation: landscape) {
  .nav__menu {
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
  }
}

 .nav__menu {
    position: fixed;
    bottom: 0;
    background-color: var(--container-color);
    box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
    height: 4rem;
    padding: 0 1rem;
    display: grid;
    align-content: center;
    border-radius: 1.25rem 1.25rem 0 0;
    transition: .4s;
  }
/*}*/

#nav-menu .nav__list,
#nav-menu .nav__link {
  display: flex;
}
.nav__link:hover {
  cursor: pointer;
}

#nav-menu .nav__link {
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  /*color: var(--title-color);*/
  color: white;
  font-weight: 600;
}

#nav-menu .nav__list {
  padding-left: 0!important;
  justify-content: space-around;
}

#nav-menu .nav__name {
  font-size: var(--tiny-font-size);
  /* display: none; !* Minimalist design, hidden labels */
}

#nav-menu .nav__icon {
  font-size: 1.5rem;
}

/*Active link*/
#nav-menu .active-link {
  position: relative;
  color: var(--first-color);
  /*color: var(--first-color);*/
  transition: .3s;
}

 /*Minimalist design, active link*/
/* .active-link::before{*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -.5rem;*/
/*  width: 4px;*/
/*  height: 4px;*/
/*  background-color: var(--first-color);*/
/*  border-radius: 50%;*/
/*}*/

/* Change background header */
#nav-menu .scroll-header {
  box-shadow: 0 1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
}

/* For medium devices */
@media (orientation: portrait) and (min-width: 450px) {
  .nav__menu {
    width: 100%;
    left: 0;
  }

  #nav-menu .nav__list {
    justify-content: center;
    column-gap: 3rem;
  }
}

@media (orientation: portrait) and (max-width: 450px) {
  .nav__menu {
    width: 100%;
    left: 0;
  }

  .nav__name {
    display: none;
  }
}
