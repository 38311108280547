@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap";
#nav-menu {
  --header-height: 3rem;
  --hue: 174;
  --sat: 63%;
  --first-color: #008adc;
  --first-color-alt: #008adc;
  --title-color: hsl(var(--hue), 12%, 15%);
  --text-color: hsl(var(--hue), 8%, 35%);
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #fff;
  --body-font: "Open Sans", sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --tiny-font-size: .625rem;
  z-index: 1058;
}

@media screen and (width >= 968px) {
  #nav-menu {
    --h1-font-size: 2.25rem;
    --normal-font-size: 1rem;
  }
}

#nav-menu {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  padding: 0;
}

#nav-menu ul {
  margin-top: unset;
  margin-bottom: unset;
  list-style: none;
}

#nav-menu a {
  text-decoration: none;
}

#nav-menu img {
  max-width: 100%;
  height: auto;
}

#nav-menu .section {
  padding: 4.5rem 0 2rem;
}

#nav-menu .section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  text-align: center;
  margin-bottom: 1.5rem;
}

#nav-menu .section__height {
  height: 100vh;
}

@media (orientation: landscape) {
  .nav__menu {
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.nav__menu {
  background-color: var(--container-color);
  box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, .15);
  border-radius: 1.25rem 1.25rem 0 0;
  align-content: center;
  height: 4rem;
  padding: 0 1rem;
  transition: all .4s;
  display: grid;
  position: fixed;
  bottom: 0;
}

#nav-menu .nav__list, #nav-menu .nav__link {
  display: flex;
}

.nav__link:hover {
  cursor: pointer;
}

#nav-menu .nav__link {
  color: #fff;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  font-weight: 600;
}

#nav-menu .nav__list {
  justify-content: space-around;
  padding-left: 0 !important;
}

#nav-menu .nav__name {
  font-size: var(--tiny-font-size);
}

#nav-menu .nav__icon {
  font-size: 1.5rem;
}

#nav-menu .active-link {
  color: var(--first-color);
  transition: all .3s;
  position: relative;
}

#nav-menu .scroll-header {
  box-shadow: 0 1px 12px hsla(var(--hue), var(--sat), 15%, .15);
}

@media (orientation: portrait) and (width >= 450px) {
  .nav__menu {
    width: 100%;
    left: 0;
  }

  #nav-menu .nav__list {
    justify-content: center;
    column-gap: 3rem;
  }
}

@media (orientation: portrait) and (width <= 450px) {
  .nav__menu {
    width: 100%;
    left: 0;
  }

  .nav__name {
    display: none;
  }
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #3f4458;
  border: 0;
  border-radius: 5px;
  width: 307.875px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #20222c, -1px 0 #20222c, 0 1px #20222c, 0 -1px #20222c, 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  max-height: 640px;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #20222c;
  height: 40px;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  border: solid #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #20222c;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #3f4458;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #20222c;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #3f4458;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #fff;
  fill: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #3f4458;
  flex: 1;
  height: 34px;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  z-index: 3;
  color: #fff;
  fill: #fff;
  height: 34px;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #eee;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ffffff26;
  width: 14px;
  height: 50%;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #c0bba71a;
}

.numInputWrapper span:active {
  background: #c0bba733;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #fff9;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #fff9;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #ffffff80;
}

.numInputWrapper:hover {
  background: #c0bba70d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  text-align: center;
  width: 75%;
  height: 34px;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #c0bba70d;
}

.flatpickr-current-month .numInputWrapper {
  width: 7ch�;
  width: 6ch;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  height: auto;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #ffffff80;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  background: #3f4458;
  border: none;
  border-radius: 0;
  outline: none;
  width: auto;
  height: auto;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #c0bba70d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #3f4458;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  background: none;
  align-items: center;
  width: 100%;
  height: 28px;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #fff;
  text-align: center;
  background: #3f4458;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  align-items: flex-start;
  width: 307.875px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #20222c;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #fffffff2;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #646c8c;
  border-color: #646c8c;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #eee;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #3f4458;
  background: #eee;
  border-color: #eee;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #80cbc4;
  border-color: #80cbc4;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #80cbc4;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #646c8c, 5px 0 #646c8c;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #ffffff4d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #ffffff1a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #80cbc4, 5px 0 #80cbc4;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #20222c;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  color: #ffffff4d;
  cursor: default;
  background: none;
  border: none;
  width: 100%;
  max-width: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  height: 0;
  max-height: 40px;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  float: left;
  flex: 1;
  width: 40%;
  height: 40px;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fffffff2;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #fffffff2;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #fffffff2;
  box-sizing: border-box;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #fffffff2;
  -webkit-user-select: none;
  user-select: none;
  align-self: center;
  width: 2%;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  text-align: center;
  outline: 0;
  width: 18%;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #6a7395;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

#scrollContainer::-webkit-scrollbar {
  display: none;
}

#scrollContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#headerRow h1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.header-margins-vertical {
  margin-top: 3rem !important;
  margin-left: 4rem !important;
}

.header-margins-horizontal {
  margin-left: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button {
  font: inherit;
  text-transform: none;
  -webkit-appearance: button;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  margin: 0;
  overflow: visible;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button {
  border-radius: 0;
}

timeline-container ul {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  list-style-position: inside;
}

.main-container {
  background: #fafafa;
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.main-container .content-container {
  flex: auto;
  min-height: 1px;
  display: flex;
}

.main-container .content-container .content-area {
  -webkit-overflow-scrolling: touch;
  flex: auto;
  padding: 1rem;
  overflow-y: auto;
}

.main-container .content-container .content-area > :first-child {
  margin-top: 0;
}

@media print {
  .main-container {
    height: auto;
  }
}

clr-icon {
  vertical-align: middle;
  fill: currentColor;
  width: 16px;
  height: 16px;
  margin: 0;
  transition: transform .5s;
  display: inline-block;
}

clr-icon > * {
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: block;
}

clr-icon > svg {
  transition: inherit;
}

clr-icon .clr-i-solid, clr-icon > svg title {
  display: none;
}

.clr-timeline {
  padding: .5rem;
  display: flex;
}

.clr-timeline-step {
  transition: transform .2s;
  display: flex;
  position: relative;
}

.hoverer {
  z-index: 2;
}

.hoverer:hover {
  cursor: pointer;
}

.clr-timeline-step clr-icon {
  color: #0079b8;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
}

.clr-timeline-step-header {
  font-size: 1.1375rem;
  line-height: 1.575rem;
  transition: transform .5s;
}

.btn.files {
  transition: transform .5s;
}

.clr-timeline-step-title {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: .25rem;
  font-size: 1.1375rem;
  font-weight: bold;
  line-height: 1.575rem;
  transition: transform .5s;
  display: -webkit-box;
  overflow: hidden;
}

.clr-timeline-step-description {
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: .962499rem;
  line-height: 1.4rem;
  transition: transform .5s;
  display: -webkit-box;
  overflow: hidden;
}

.clr-timeline-step-description button {
  margin-top: .333333rem;
  display: block;
}

.clr-timeline-step-description img {
  width: 100%;
  margin-top: .333333rem;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step {
  flex-direction: column;
  min-width: 15rem;
  margin-left: 5rem;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step:not(:last-of-type) .clr-timeline-step-body:before {
  content: "";
  background: #9a9a9a;
  width: calc(100% + 3.66667rem);
  height: .1rem;
  position: absolute;
  transform: translate(1.41667rem, -.791667rem);
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-header {
  white-space: nowrap;
  margin-bottom: .333333rem;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-body {
  flex-direction: column;
  display: flex;
}

.clr-timeline.clr-timeline-horizontal .clr-timeline-step-title {
  margin-top: .333333rem;
}

.clr-timeline.clr-timeline-vertical {
  flex-direction: column;
  min-width: 13.3333rem;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step {
  flex-direction: row;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step:not(:last-of-type) {
  margin-bottom: 1.5rem;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step:not(:last-of-type) .clr-timeline-step-body:before {
  content: "";
  background: #9a9a9a;
  width: .083333rem;
  height: calc(100% + .166666rem);
  position: absolute;
  transform: translate(-1.29167rem, 1.46667rem);
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step-header {
  text-align: right;
  width: 2.5rem;
  min-width: 7rem;
  margin-right: .5rem;
}

.clr-timeline.clr-timeline-vertical .clr-timeline-step-body {
  flex-direction: column;
  min-width: 7.33333rem;
  margin-left: .5rem;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.masked-overflow {
  --scrollbar-width: 8px;
  --mask-height: 32px;
  padding-bottom: var(--mask-height);
  --mask-image-content: linear-gradient(to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  height: 300px;
  padding-right: 20px;
  overflow-y: auto;
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
}

.ripple {
  opacity: 0;
  background: #06f9;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: fixed;
  transform: scale(0);
}

.animate {
  animation: 1s cubic-bezier(0, 0, .2, 1) ripple-mo;
}

@keyframes ripple-mo {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(20);
  }
}

.blurredEntry {
  color: #0000;
  text-shadow: 0 0 7px #fff;
  padding-left: 5px;
}

.blurredEntryHideCount {
  display: none !important;
}

.blurredEntryIsShared {
  color: green !important;
}

.blurredEntryIsSharedButNoMorePro {
  color: #ffc107 !important;
}

.ec-days, .ec-day, .ec-day-title, .ec-resource {
  flex: 1 1 0;
  min-width: 0;
  max-width: 100%;
}

.ec {
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  display: flex;
}

.ec ::-webkit-scrollbar {
  background: #fff;
}

.ec ::-webkit-scrollbar-thumb {
  box-shadow: none;
  background: #dadce0;
  border: 4px solid #fff;
  border-radius: 8px;
  min-height: 40px;
}

.ec :hover::-webkit-scrollbar-thumb {
  background: #bdc1c6;
}

.ec-hidden-scroll {
  visibility: hidden;
  flex-shrink: 0;
  display: none;
  overflow-y: scroll;
}

.ec-with-scroll .ec-hidden-scroll {
  display: block;
}

.ec-toolbar {
  flex: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

.ec-toolbar > * {
  margin-bottom: -.5em;
}

.ec-toolbar > * > * {
  margin-bottom: .5em;
}

.ec-toolbar > * > :not(:last-child) {
  margin-right: .75em;
}

.ec-title {
  margin: 0;
}

.ec-button {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.ec-button:not(:disabled) {
  color: #212529;
  cursor: pointer;
}

.ec-button:not(:disabled):hover, .ec-button.ec-active {
  background-color: #ececec;
  border-color: #b1bbc4;
}

.ec-button-group {
  display: inline-flex;
}

.ec-button-group .ec-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.ec-button-group .ec-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ec-icon {
  width: 1em;
  display: inline-block;
}

.ec-icon.ec-prev:after, .ec-icon.ec-next:after {
  content: "";
  border-top: 2px solid #212529;
  border-right: 2px solid #212529;
  width: .5em;
  height: .5em;
  display: inline-block;
  position: relative;
}

.ec-icon.ec-prev:after {
  transform: rotate(-135deg)translate(-2px, 2px);
}

.ec-icon.ec-next:after {
  transform: rotate(45deg)translate(-2px, 2px);
}

.ec-header, .ec-all-day, .ec-body, .ec-days, .ec-day {
  border: 1px solid #dadce0;
}

.ec-header {
  flex-shrink: 0;
  display: flex;
}

.ec-header .ec-resource {
  flex-direction: column;
}

.ec-header .ec-resource .ec-days {
  border-top-style: solid;
}

.ec-header .ec-days {
  border-bottom: none;
}

.ec-header .ec-day {
  text-align: center;
  text-overflow: ellipsis;
  min-height: 24px;
  line-height: 24px;
  overflow: hidden;
}

.ec-all-day {
  border-top: none;
  flex-shrink: 0;
  display: flex;
}

.ec-all-day .ec-days {
  border-bottom: none;
}

.ec-all-day .ec-day {
  padding-bottom: 4px;
}

.ec-all-day .ec-event-time {
  display: none;
}

.ec-body {
  position: relative;
  overflow: hidden auto;
}

.ec:not(.ec-list) .ec-body {
  border-top: none;
}

.ec-month .ec-body {
  flex: auto;
}

.ec-sidebar {
  flex-direction: column;
  flex: none;
  justify-content: center;
  width: auto;
  max-width: 100%;
  padding: 0 4px 0 8px;
  display: flex;
}

.ec-sidebar-title {
  visibility: hidden;
  text-align: right;
  height: 0;
  overflow-y: hidden;
}

.ec-all-day .ec-sidebar-title {
  visibility: visible;
  height: auto;
  padding: 8px 0;
}

.ec-content {
  display: flex;
}

.ec-month .ec-content {
  flex-direction: column;
  height: 100%;
}

.ec-month .ec-uniform .ec-content {
  overflow: hidden;
}

.ec-list .ec-content {
  flex-direction: column;
}

.ec-resource {
  display: flex;
}

.ec-days {
  border-style: none none solid;
  display: flex;
}

.ec-days:last-child {
  border-bottom: none;
}

.ec-month .ec-days, .ec-resource .ec-days {
  flex: 1 0 auto;
}

.ec-month .ec-uniform .ec-days {
  flex: 1;
  min-height: 0;
}

.ec-day {
  border-style: none none none solid;
}

.ec-day.ec-today {
  background-color: #fcf8e3;
}

.ec-day.ec-highlight {
  background-color: #e5f7fe;
}

.ec-month .ec-body .ec-day {
  min-height: 5em;
  position: relative;
}

.ec-month .ec-uniform .ec-day {
  min-height: 0;
}

.ec-month .ec-day:first-child {
  border-left: none;
}

.ec-day.ec-other-month .ec-day-head {
  opacity: .3;
}

.ec-list .ec-day {
  z-index: 2;
  background-color: #fff;
  border-style: solid none;
  flex: 1 0 auto;
  padding: 8px 14px;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.ec-list .ec-day:first-child {
  border-top: none;
}

.ec-month .ec-day-head {
  text-align: right;
  padding: 4px 4px 3px;
}

.ec-month .ec-day-foot {
  padding: 2px;
  font-size: .85em;
  position: absolute;
  bottom: 0;
}

.ec-month .ec-day-foot a {
  cursor: pointer;
}

.ec-list .ec-day-side {
  float: right;
}

.ec-list .ec-no-events {
  text-align: center;
  padding: 5em 0;
}

.ec-events {
  margin: 0 6px 0 0;
}

.ec-week .ec-events, .ec-events.ec-preview {
  position: relative;
}

.ec-event {
  color: #fff;
  box-sizing: border-box;
  z-index: 1;
  background-color: #039be5;
  border-radius: 3px;
  padding: 2px;
  font-size: .85em;
  line-height: 1.5;
  display: flex;
  box-shadow: 0 0 1px #dadce0;
}

.ec-month .ec-event, .ec-all-day .ec-event {
  position: relative;
}

.ec-week .ec-body .ec-event {
  position: absolute;
}

.ec-list .ec-event {
  color: inherit;
  background-color: #0000;
  border-radius: 0;
  flex-direction: row;
  padding: 8px 14px;
}

.ec-event.ec-preview {
  z-index: 1000;
  -webkit-user-select: none;
  user-select: none;
  opacity: .8;
  width: 100%;
  position: absolute;
}

.ec-event.ec-pointer {
  color: inherit;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
  box-shadow: none;
  display: none;
  position: absolute;
}

.ec-day:hover .ec-event.ec-pointer {
  display: flex;
}

.ec-event-body {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.ec-month .ec-event-body, .ec-all-day .ec-event-body {
  flex-direction: row;
}

.ec-event-tag {
  border-radius: 2px;
  width: 4px;
  margin-right: 8px;
}

.ec-event-time {
  white-space: nowrap;
  flex-shrink: 0;
  margin: 0 0 1px;
  overflow: hidden;
}

.ec-month .ec-event-time {
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0 3px 0 0;
}

.ec-event-title {
  overflow: hidden;
}

.ec-month .ec-event-title, .ec-all-day .ec-event-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 1.5em;
}

.ec-week .ec-body .ec-event-title {
  position: sticky;
  top: 0;
}

.ec-list .ec-event-title {
  font-size: 1rem;
}

.ec-draggable {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.ec-ghost {
  opacity: .5;
  -webkit-user-select: none;
  user-select: none;
}

.ec-bg-events {
  position: relative;
}

.ec-bg-event {
  opacity: .3;
  z-index: 1;
  background-color: #dadce0;
  width: 100%;
  position: absolute;
}

.ec-hidden-times {
  visibility: hidden;
  height: 0;
  overflow-y: hidden;
}

.ec-time, .ec-line {
  height: 24px;
}

.ec-time {
  text-align: right;
  white-space: nowrap;
  line-height: 24px;
  position: relative;
  top: -12px;
}

.ec-lines {
  width: 8px;
}

.ec-line:not(:first-child):after {
  content: "";
  pointer-events: none;
  border-bottom: 1px solid #dadce0;
  width: 100%;
  position: absolute;
}

.ec-body:not(.ec-compact) .ec-line:nth-child(2n):after {
  border-bottom-style: dotted;
}

.ec-popup {
  z-index: 1010;
  background-color: #fff;
  border-radius: 6px;
  outline: 1px solid #0000;
  flex-direction: column;
  width: 110%;
  min-width: 180px;
  padding: 8px 10px 14px;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: 0 1px 3px #3c40434d, 0 4px 8px 3px #3c404326;
}

.ec-popup .ec-day-head {
  text-align: left;
  justify-content: space-between;
  display: flex;
}

.ec-popup .ec-day-head a {
  cursor: pointer;
  font-size: 1.5em;
  line-height: .8;
}

.ec-popup .ec-events {
  min-height: 0;
  margin: 0;
  overflow: auto;
}

.ec-extra {
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  margin-left: -6.5px;
  position: relative;
  overflow: hidden;
}

.ec-now-indicator {
  z-index: 1005;
  pointer-events: none;
  border-top: 2px solid #ea4335;
  width: 100%;
  position: absolute;
}

.ec-now-indicator:before {
  content: "";
  pointer-events: none;
  background: #ea4335;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -7px;
  position: absolute;
}

.ec-resizer {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
}

.ec-month .ec-resizer, .ec-all-day .ec-resizer {
  cursor: ew-resize;
  width: 50%;
  max-width: 8px;
  top: 0;
  bottom: 0;
  right: 0;
}

.ec-week .ec-body .ec-resizer {
  cursor: ns-resize;
  height: 50%;
  max-height: 8px;
  bottom: 0;
  left: 0;
  right: 0;
}

.ec-dragging {
  cursor: pointer !important;
}

.ec-resizing-y {
  cursor: ns-resize !important;
}

.ec-resizing-x {
  cursor: ew-resize !important;
}
/*# sourceMappingURL=index.517eca85.css.map */
