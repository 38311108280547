/* Grid */
.ec-days,
.ec-day,
.ec-day-title,
.ec-resource {
  flex: 1 1 0;
  min-width: 0;
  max-width: 100%;
}

.ec {
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  /* Scrollbar */
}
.ec ::-webkit-scrollbar {
  background: #fff;
}
.ec ::-webkit-scrollbar-thumb {
  border: 4px solid #fff;
  box-shadow: none;
  background: #dadce0;
  border-radius: 8px;
  min-height: 40px;
}
.ec :hover::-webkit-scrollbar-thumb {
  background: #bdc1c6;
}

.ec-hidden-scroll {
  display: none;
  overflow-y: scroll;
  visibility: hidden;
  flex-shrink: 0;
}
.ec-with-scroll .ec-hidden-scroll {
  display: block;
}

/* Toolbar */
.ec-toolbar {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.ec-toolbar > * {
  margin-bottom: -0.5em;
}
.ec-toolbar > * > * {
  margin-bottom: 0.5em;
}
.ec-toolbar > * > *:not(:last-child) {
  margin-right: 0.75em;
}

.ec-title {
  margin: 0;
}

.ec-button {
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.ec-button:not(:disabled) {
  color: #212529;
  cursor: pointer;
}
.ec-button:not(:disabled):hover, .ec-button.ec-active {
  background-color: #ececec;
  border-color: #b1bbc4;
}

.ec-button-group {
  display: inline-flex;
}
.ec-button-group .ec-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}
.ec-button-group .ec-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ec-icon {
  display: inline-block;
  width: 1em;
}
.ec-icon.ec-prev:after, .ec-icon.ec-next:after {
  content: "";
  position: relative;
  width: 0.5em;
  height: 0.5em;
  border-top: 2px solid #212529;
  border-right: 2px solid #212529;
  display: inline-block;
}
.ec-icon.ec-prev:after {
  transform: rotate(-135deg) translate(-2px, 2px);
}
.ec-icon.ec-next:after {
  transform: rotate(45deg) translate(-2px, 2px);
}

/* Header */
.ec-header,
.ec-all-day,
.ec-body,
.ec-days,
.ec-day {
  border: 1px solid #dadce0;
}

.ec-header {
  display: flex;
  flex-shrink: 0;
}
.ec-header .ec-resource {
  flex-direction: column;
}
.ec-header .ec-resource .ec-days {
  border-top-style: solid;
}
.ec-header .ec-days {
  border-bottom: none;
}
.ec-header .ec-day {
  min-height: 24px;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* All Day */
.ec-all-day {
  display: flex;
  flex-shrink: 0;
  border-top: none;
}
.ec-all-day .ec-days {
  border-bottom: none;
}
.ec-all-day .ec-day {
  padding-bottom: 4px;
}
.ec-all-day .ec-event-time {
  display: none;
}

/* Body */
.ec-body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.ec:not(.ec-list) .ec-body {
  border-top: none;
}
.ec-month .ec-body {
  flex: 1 1 auto;
}

.ec-sidebar {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding: 0 4px 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ec-sidebar-title {
  visibility: hidden;
  overflow-y: hidden;
  height: 0;
  text-align: right;
}
.ec-all-day .ec-sidebar-title {
  visibility: visible;
  height: auto;
  padding: 8px 0;
}

.ec-content {
  display: flex;
}
.ec-month .ec-content {
  flex-direction: column;
  height: 100%;
}
.ec-month .ec-uniform .ec-content {
  overflow: hidden;
}
.ec-list .ec-content {
  flex-direction: column;
}

.ec-resource {
  display: flex;
}

.ec-days {
  display: flex;
  border-style: none none solid;
}
.ec-days:last-child {
  border-bottom: none;
}
.ec-month .ec-days, .ec-resource .ec-days {
  flex: 1 0 auto;
}
.ec-month .ec-uniform .ec-days {
  flex: 1 1 0%;
  min-height: 0;
}

.ec-day {
  border-style: none none none solid;
}
.ec-day.ec-today {
  background-color: #fcf8e3;
}
.ec-day.ec-highlight {
  background-color: #e5f7fe;
}
.ec-month .ec-body .ec-day {
  min-height: 5em;
  position: relative;
}
.ec-month .ec-uniform .ec-day {
  min-height: 0;
}
.ec-month .ec-day:first-child {
  border-left: none;
}
.ec-day.ec-other-month .ec-day-head {
  opacity: 0.3;
}
.ec-list .ec-day {
  flex: 1 0 auto;
  background-color: #fff;
  border-style: solid none;
  padding: 8px 14px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2;
}
.ec-list .ec-day:first-child {
  border-top: none;
}

.ec-month .ec-day-head {
  text-align: right;
  padding: 4px 4px 3px;
}
.ec-month .ec-day-foot {
  position: absolute;
  bottom: 0;
  padding: 2px;
  font-size: 0.85em;
}
.ec-month .ec-day-foot a {
  cursor: pointer;
}

.ec-list .ec-day-side {
  float: right;
}
.ec-list .ec-no-events {
  text-align: center;
  padding: 5em 0;
}

.ec-events {
  margin: 0 6px 0 0;
}
.ec-week .ec-events, .ec-events.ec-preview {
  position: relative;
}

.ec-event {
  display: flex;
  padding: 2px;
  color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0 1px 0 #dadce0;
  background-color: #039be5;
  border-radius: 3px;
  font-size: 0.85em;
  line-height: 1.5;
  z-index: 1;
}
.ec-month .ec-event, .ec-all-day .ec-event {
  position: relative;
}
.ec-week .ec-body .ec-event {
  position: absolute;
}
.ec-list .ec-event {
  flex-direction: row;
  padding: 8px 14px;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}
.ec-event.ec-preview {
  position: absolute;
  z-index: 1000;
  width: 100%;
  user-select: none;
  opacity: 0.8;
}
.ec-event.ec-pointer {
  color: inherit;
  pointer-events: none;
  user-select: none;
  position: absolute;
  z-index: 0;
  box-shadow: none;
  display: none;
}
.ec-day:hover .ec-event.ec-pointer {
  display: flex;
}

.ec-event-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ec-month .ec-event-body, .ec-all-day .ec-event-body {
  flex-direction: row;
}

.ec-event-tag {
  width: 4px;
  border-radius: 2px;
  margin-right: 8px;
}

.ec-event-time {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 1px 0;
  flex-shrink: 0;
}
.ec-month .ec-event-time {
  margin: 0 3px 0 0;
  max-width: 100%;
  text-overflow: ellipsis;
}

.ec-event-title {
  overflow: hidden;
}
.ec-month .ec-event-title, .ec-all-day .ec-event-title {
  min-height: 1.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ec-week .ec-body .ec-event-title {
  position: sticky;
  top: 0;
}
.ec-list .ec-event-title {
  font-size: 1rem;
}

.ec-draggable {
  cursor: pointer;
  user-select: none;
}

.ec-ghost {
  opacity: 0.5;
  user-select: none;
}

.ec-bg-events {
  position: relative;
}

.ec-bg-event {
  position: absolute;
  background-color: #dadce0;
  opacity: 0.3;
  width: 100%;
  z-index: 1;
}

.ec-hidden-times {
  visibility: hidden;
  overflow-y: hidden;
  height: 0;
}

.ec-time,
.ec-line {
  height: 24px;
}

.ec-time {
  position: relative;
  line-height: 24px;
  top: -12px;
  text-align: right;
  white-space: nowrap;
}

.ec-lines {
  width: 8px;
}

.ec-line:not(:first-child):after {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #dadce0;
  pointer-events: none;
}

.ec-body:not(.ec-compact) .ec-line:nth-child(even):after {
  border-bottom-style: dotted;
}

.ec-popup {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 110%;
  min-width: 180px;
  z-index: 1010;
  padding: 8px 10px 14px;
  background-color: #fff;
  border-radius: 6px;
  outline: 1px solid transparent;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
}
.ec-popup .ec-day-head {
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.ec-popup .ec-day-head a {
  cursor: pointer;
  font-size: 1.5em;
  line-height: 0.8;
}
.ec-popup .ec-events {
  margin: 0;
  min-height: 0;
  overflow: auto;
}

.ec-extra {
  position: relative;
  height: 100%;
  overflow: hidden;
  margin-left: -6.5px;
  user-select: none;
}

.ec-now-indicator {
  position: absolute;
  z-index: 1005;
  width: 100%;
  border-top: #ea4335 solid 2px;
  pointer-events: none;
}
.ec-now-indicator:before {
  background: #ea4335;
  border-radius: 50%;
  content: "";
  position: absolute;
  height: 12px;
  margin-top: -7px;
  width: 12px;
  pointer-events: none;
}

.ec-resizer {
  position: absolute;
  user-select: none;
}
.ec-month .ec-resizer, .ec-all-day .ec-resizer {
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  max-width: 8px;
  cursor: ew-resize;
}
.ec-week .ec-body .ec-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  max-height: 8px;
  cursor: ns-resize;
}

.ec-dragging {
  cursor: pointer !important;
}

.ec-resizing-y {
  cursor: ns-resize !important;
}

.ec-resizing-x {
  cursor: ew-resize !important;
}